<template>
  <div class="collect_page">
    <div class="collect_list">
      <div class="collect_item" v-for="(item, index) in collectList">
        <h4 @click="toDetails(item.id)">{{ item.title }}</h4>
        <p>发布于{{ item.publishDateString }}</p>
        <p @click="toDetails(item.id)">
          {{ item.topic ? "#" + item.topic + "#" : "" }}{{ item.introduction }}
        </p>
        <div>
          <a
            v-for="(industryItem, industryIndex) in item.industryName"
            :data-id="item.industry[industryIndex]"
            >{{ industryItem }}</a
          >
          <a
            v-for="(productItem, productIndex) in item.productName"
            :data-id="item.product[productIndex]"
            >{{ productItem }}</a
          >
        </div>
        <div>
          <a @click="like(item.id, item.isLike, index)" :class="[item.isLike ? 'active' : '']" title="点赞">
            <svg-icon icon-class="sword"></svg-icon>
            <svg-icon icon-class="sword_a"></svg-icon>
            <span>{{ item.likeCount }}</span>
          </a>
          <a @click="collect(item.id, index)" title="收藏">
            <svg-icon icon-class="shield_a"></svg-icon>
            <span>{{ item.collectCount }}</span>
          </a>
          <a :class="[item.isComment ? 'active' : '']" title="评论">
            <svg-icon icon-class="discuss"></svg-icon>
            <svg-icon icon-class="discuss_a"></svg-icon>
            <span>{{ item.comment }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="noData" v-if="this.collectList.length == 0">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
    <div class="collect_pagination" v-if="recordCount > pageSize">
      <el-pagination
        background
        layout="pager, next"
        next-text="下一页"
        :page-size="pageSize"
        :pager-count="pageCount"
        :total="recordCount"
        @current-change="pageClick"
        :current-page="listParams.page"
        @next-click="pageClick"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Headers from "@/components/header.vue";
import * as forum from "../../api/forum.js";
import { debounce } from "@/utils/common";
import { dataState } from "@/api/all.js";

export default {
  name: "collect",
  components: {
    Headers,
  },
  props: ["searchVal", "isSearch"],
  watch: {
    searchVal(val) {
      this.searchTitle = val;
    },
    isSearch(val) {
      if (val) {
        this.search();
      }
    },
  },
  data() {
    return {
      searchTitle: "",
      pageSize: 5,
      pageCount: 5,
      recordCount: 0,
      listParams: {
        page: 1,
        limit: 5,
        productType: 0,
        title: "",
      },
      collectList: [],
    };
  },
  created() {
    this.getList();
    this.$emit("change", 3, "收藏夹");
  },
  mounted() {
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "Collect",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    search() {
      this.listParams.page = 1;
      this.listParams.title = this.searchTitle;
      this.getList();
    },
		// !列表数据
    getList() {
      forum.getCollect(this.listParams).then((res) => {
        if (res.status == 1) {
          res.data.map((item, index) => {
            if (item.industry != "") {
              item.industry = JSON.parse(item.industry);
              item.industryName = JSON.parse(item.industryName);
            } else {
              item.industry = [];
              item.industryName = [];
            }
            if (item.product != "") {
              item.product = JSON.parse(item.product);
              item.productName = JSON.parse(item.productName);
            } else {
              item.product = [];
              item.productName = [];
            }
          });
          this.collectList = res.data;
          this.recordCount = res.recordCount;
        }
      });
    },
    toDetails(id) {
      this.$router.push("/Home/forumDetails?id=" + id);
    },
    pageClick(page) {
      this.listParams.page = page;
      this.getList();
    },
		// !点赞与取消点赞
    like(id, isLike, index) {
      debounce(() => {
        if (!isLike) {
          forum.likeArticle({ forumManagementId: id }).then((res) => {
            this.$toast({ message: res.message, duration: 1000 });
            if (res.status == 1) {
              if (res.message > 0) {
                this.$toast("点赞成功！ +" + res.message + " 积分");
              } else {
                this.$toast("点赞成功！");
              }
              this.collectList[index].isLike = true;
              this.collectList[index].likeCount++;
            } else {
              this.$toast(res.message);
            }
          });
        } else {
          forum.likeCancelArticle({ forumManagementId: id }).then((res) => {
            this.$toast({ message: res.message, duration: 1000 });
            if (res.status == 1) {
              this.collectList[index].isLike = false;
              this.collectList[index].likeCount--;
            }
          });
        }
      }, 1000);
    },
		// !取消收藏
    collect(id, index) {
      forum.collectCancelArticle({ forumManagementId: id }).then((res) => {
        this.$toast({ message: res.message, duration: 1000 });
        if (res.status == 1) {
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.collect_page {
  .collect_list {
    .collect_item {
      padding: 40px;
      border-color: #e6e6e6;
      border-style: solid;
      border-width: 0 0 1px 0;
      h4 {
        font-size: 16px;
        color: #363d42;
        cursor: pointer;
      }
      & > p:first-of-type {
        font-size: 16px;
        color: #738294;
        margin: 12px 0 16px 0;
      }
      & > p:nth-of-type(2) {
        font-size: 16px;
        color: #738294;
        line-height: 26px;
        margin: 0 0 20px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        cursor: pointer;
      }
      & > div:first-of-type {
        display: flex;
        margin: 0 0 32px 0;
        a {
          display: block;
          padding: 0 8px;
          min-width: 34px;
          border: 1px solid #e6e6e6;
          height: 26px;
          line-height: 26px;
          font-size: 16px;
          color: #6b18a8;
          text-align: center;
          font-weight: 700;
          margin: 0 12px 0 0;
        }
      }
      & > div:nth-of-type(2) {
        display: flex;
        a {
          display: flex;
          align-items: center;
          margin-right: 28px;
          position: relative;

          span {
            font-size: 16px;
            color: #363d42;
            margin-left: 8px;
          }

          svg {
            transition: all 0.5s;
          }
          svg:first-of-type {
            opacity: 1;
          }
          svg:nth-of-type(2) {
            opacity: 0;
            position: absolute;
            left: 0;
          }

          &:first-of-type {
            cursor: pointer;
            svg {
              width: 17px;
              height: 17px;
            }
          }

          &:nth-of-type(2) {
            cursor: pointer;
            svg {
              width: 17px;
              height: 21px;
            }
          }

          &:nth-of-type(3) {
            svg {
              width: 18px;
              height: 18px;
            }
          }
          &.active {
            background: none;
            border: 0;
            svg:first-of-type {
              opacity: 0;
            }
            svg:nth-of-type(2) {
              opacity: 1;
            }
          }
        }
      }
      &:first-of-type {
        border-width: 1px 0 1px 0;
      }
    }
  }

  .collect_pagination {
    margin: 60px 0 100px 0;

    .el-pagination {
      display: flex;
      justify-content: flex-end;

      /deep/ .el-pager li {
        width: 45px;
        height: 45px;
        font-size: 16px;
        color: #363d42;
        text-align: center;
        line-height: 45px;
      }

      /deep/ .el-pager li.active {
        color: #fff;
        background-color: #6b18a8;
      }

      /deep/ .btn-next {
        width: 95px;
        height: 45px;
        color: #fff;
        background-color: #6b18a8;
      }
    }
  }
}
</style>
